import { Datagrid, TextField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const CharacterCardList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <NumField source="character_id" label="character_id" sortable={false} />
      <NumField source="attribute" label="attribute" sortable={false} />
      <NumField source="level_min" label="level_min" sortable={false} />
      <NumField source="level_max" label="level_max" sortable={false} />
      <NumField
        source="bonus_chain_group_id"
        label="bonus_chain_group_id"
        sortable={false}
      />
      <NumField
        source="charactercardparameter2_group_id"
        label="charactercardparameter2_group_id"
        sortable={false}
      />
      <NumField
        source="panel_board_group_id"
        label="panel_board_group_id"
        sortable={false}
      />
      <NumField source="exp_group_id" label="exp_group_id" sortable={false} />
      <TextField source="card_name" label="card_name" sortable={false} />
      <TextField
        source="card_name_ruby"
        label="card_name_ruby"
        sortable={false}
      />
      <TextField source="flavor_text" label="flavor_text" sortable={false} />
      <TextField
        source="gacha_short_lyric"
        label="gacha_short_lyric"
        sortable={false}
      />
      <TextField source="sign" label="sign" sortable={false} />
      <NumField
        source="sign_default_position"
        label="sign_default_position"
        sortable={false}
      />
      <NumField
        source="sign_release_probability"
        label="sign_release_probability"
        sortable={false}
      />
      <NumField
        source="character_card_model_id"
        label="character_card_model_id"
        sortable={false}
      />
      <NumField
        source="card_illust_asset_id"
        label="card_illust_asset_id"
        sortable={false}
      />
      <TextField
        source="home_text_bubble_position"
        label="home_text_bubble_position"
        sortable={false}
      />
      <NumField
        source="fragment_item_id"
        label="fragment_item_id"
        sortable={false}
      />
    </Datagrid>
  </CommonMasterList>
);
