import { Datagrid, NumberInput, TextField, ReferenceField } from "react-admin";
import {
  CommonList,
  NumField,
  ReferenceMissionField,
  YMDHMSDateField,
} from "../Common";

const userMissionFilters = [
  <NumberInput source="MissionId" label="MissionId" />,
  <NumberInput source="Progress" label="進捗" />,
];

export const UserMissionList = (props: any) => (
  <CommonList {...props} addFilters={userMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="MissionId" label="MissionId" />
      <ReferenceMissionField label="ミッション名" />
      <ReferenceField source="MissionId" reference="Mission" label="Value">
        <TextField source="value" />
      </ReferenceField>
      <NumField source="Progress" label="進捗" />
      <YMDHMSDateField source="LastProgressedAt" label="進捗更新日時" />
      <YMDHMSDateField source="RewardReceivedAt" label="報酬受取日時" />
    </Datagrid>
  </CommonList>
);
