import { Datagrid, TextField, BooleanField, DateField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const MissionList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <NumField
        source="mission_group_id"
        label="mission_group_id"
        sortable={false}
      />
      <NumField source="category" label="category" sortable={false} />
      <NumField source="type" label="type" sortable={false} />
      <NumField source="target" label="target" sortable={false} />
      <NumField source="value" label="value" sortable={false} />
      <NumField source="default_value" label="default_value" sortable={false} />
      <NumField source="condition_1" label="condition_1" sortable={false} />
      <NumField source="condition_2" label="condition_2" sortable={false} />
      <NumField source="condition_3" label="condition_3" sortable={false} />
      <BooleanField
        source="is_and_condition"
        label="is_and_condition"
        sortable={false}
      />
      <BooleanField
        source="should_overwrite_value"
        label="should_overwrite_value"
        sortable={false}
      />
      <TextField source="text" label="text" sortable={false} />
      <NumField source="exp" label="exp" sortable={false} />
      <NumField
        source="mission_reward_group_id"
        label="mission_reward_group_id"
        sortable={false}
      />
      <DateField source="open_at" label="open_at" sortable={false} />
      <DateField source="close_at" label="close_at" sortable={false} />
    </Datagrid>
  </CommonMasterList>
);
