import { CommonList, YMDHMSDateField, NumField } from "components/Common";
import {
  Datagrid,
  TextField,
  NumberInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
} from "react-admin";

const userQuestStoryEpisodeFilters = [
  <NumberInput source="QuestStoryEpisodeId" label="QuestStoryEpisodeId" />,
  <BooleanInput source="IsFavorite" label="お気に入り" />,
  <BooleanInput source="IsBookmarked" label="ブックマーク" />,
];

export const UserQuestStoryEpisodeList = (props: any) => (
  <CommonList {...props} addFilters={userQuestStoryEpisodeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="QuestStoryEpisodeId" label="QuestStoryEpisodeId" />
      <ReferenceField
        source="QuestStoryEpisodeId"
        reference="QuestStoryEpisode"
        label="タイトル"
      >
        <TextField source="flavor" />
      </ReferenceField>
      <YMDHMSDateField source="ReadAt" label="既読日時" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <BooleanField source="IsBookmarked" label="ブックマーク" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
