import {
  Datagrid,
  TextField,
  NumberInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userCardStoryEpisodeFilters = [
  <NumberInput source="CardStoryEpisodeId" label="CardStoryEpisodeId" />,
  <BooleanInput source="IsFavorite" label="お気に入り" />,
  <BooleanInput source="IsBookmarked" label="ブックマーク" />,
];

export const UserCardStoryEpisodeList = (props: any) => (
  <CommonList {...props} addFilters={userCardStoryEpisodeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CardStoryEpisodeId" label="CardStoryEpisodeId" />
      <ReferenceField
        source="CardStoryEpisodeId"
        reference="CardStoryEpisode"
        label="タイトル"
      >
        <TextField source="flavor" />
      </ReferenceField>
      <YMDHMSDateField source="ReadAt" label="既読日時" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <BooleanField source="IsBookmarked" label="ブックマーク" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
