import { Datagrid, TextField, DateField, BooleanField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const ItemList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <TextField source="name" label="name" sortable={false} />
      <NumField source="tab_category" label="tab_category" sortable={false} />
      <NumField source="rare" label="rare" sortable={false} />
      <NumField source="type" label="type" sortable={false} />
      <NumField source="sub_type" label="sub_type" sortable={false} />
      <NumField source="value" label="value" sortable={false} />
      <NumField source="limit_count" label="limit_count" sortable={false} />
      <DateField source="expired_at" label="expired_at" sortable={false} />
      <TextField source="comment" label="comment" sortable={false} />
      <NumField source="destination" label="destination" sortable={false} />
      <NumField
        source="item_illust_asset_id"
        label="item_illust_asset_id"
        sortable={false}
      />
      <BooleanField source="obtain" label="obtain" sortable={false} />
      <BooleanField source="use" label="use" sortable={false} />
      <BooleanField source="growth" label="growth" sortable={false} />
      <BooleanField source="gacha" label="gacha" sortable={false} />
    </Datagrid>
  </CommonMasterList>
);
