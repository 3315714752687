import { Datagrid, TextField, BooleanField, NumberField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const QuestStoryEpisodeList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <NumField source="chapter_id" label="chapter_id" sortable={false} />
      <NumField
        source="release_condition_quest_difficulty_id"
        label="release_condition_quest_difficulty_id"
        sortable={false}
      />
      <BooleanField
        source="release_condition_shop"
        label="release_condition_shop"
        sortable={false}
      />
      <NumField
        source="conversation_scene_id"
        label="conversation_scene_id"
        sortable={false}
      />
      <TextField source="title" label="title" sortable={false} />
      <TextField source="flavor" label="flavor" sortable={false} />
      <NumField
        source="background_asset_id"
        label="background_asset_id"
        sortable={false}
      />
      <NumField
        source="appear_character_1"
        label="appear_character_1"
        sortable={false}
      />
      <NumField
        source="appear_character_2"
        label="appear_character_2"
        sortable={false}
      />
      <NumField
        source="appear_character_3"
        label="appear_character_3"
        sortable={false}
      />
      <NumField
        source="appear_character_4"
        label="appear_character_4"
        sortable={false}
      />
      <NumField
        source="appear_character_5"
        label="appear_character_5"
        sortable={false}
      />
      <NumField
        source="appear_character_6"
        label="appear_character_6"
        sortable={false}
      />
      <NumField
        source="appear_character_7"
        label="appear_character_7"
        sortable={false}
      />
      <NumField
        source="appear_character_8"
        label="appear_character_8"
        sortable={false}
      />
      <NumField
        source="appear_character_9"
        label="appear_character_9"
        sortable={false}
      />
      <NumField
        source="appear_character_10"
        label="appear_character_10"
        sortable={false}
      />
      <NumField
        source="appear_character_11"
        label="appear_character_11"
        sortable={false}
      />
      <NumField
        source="appear_character_12"
        label="appear_character_12"
        sortable={false}
      />
      <NumField
        source="appear_character_13"
        label="appear_character_13"
        sortable={false}
      />
      <NumField
        source="appear_character_14"
        label="appear_character_14"
        sortable={false}
      />
      <NumField
        source="appear_character_15"
        label="appear_character_15"
        sortable={false}
      />
      <NumField
        source="appear_character_16"
        label="appear_character_16"
        sortable={false}
      />
      <NumField
        source="appear_character_17"
        label="appear_character_17"
        sortable={false}
      />
      <NumField
        source="appear_character_18"
        label="appear_character_18"
        sortable={false}
      />
      <NumField
        source="appear_character_19"
        label="appear_character_19"
        sortable={false}
      />
      <NumField
        source="appear_character_20"
        label="appear_character_20"
        sortable={false}
      />
      <NumField
        source="appear_character_21"
        label="appear_character_21"
        sortable={false}
      />
      <NumField
        source="appear_character_22"
        label="appear_character_22"
        sortable={false}
      />
      <NumField
        source="appear_character_23"
        label="appear_character_23"
        sortable={false}
      />
      <NumField
        source="appear_character_24"
        label="appear_character_24"
        sortable={false}
      />
      <NumField
        source="appear_character_25"
        label="appear_character_25"
        sortable={false}
      />
      <NumField
        source="appear_character_26"
        label="appear_character_26"
        sortable={false}
      />
      <NumField
        source="appear_character_27"
        label="appear_character_27"
        sortable={false}
      />
      <NumField
        source="appear_character_28"
        label="appear_character_28"
        sortable={false}
      />
      <NumField
        source="appear_character_29"
        label="appear_character_29"
        sortable={false}
      />
      <NumField
        source="appear_character_30"
        label="appear_character_30"
        sortable={false}
      />
      <NumField
        source="appear_character_31"
        label="appear_character_31"
        sortable={false}
      />
      <NumField
        source="appear_character_32"
        label="appear_character_32"
        sortable={false}
      />
      <NumField
        source="appear_character_33"
        label="appear_character_33"
        sortable={false}
      />
      <NumField
        source="appear_character_34"
        label="appear_character_34"
        sortable={false}
      />
      <NumField
        source="appear_character_35"
        label="appear_character_35"
        sortable={false}
      />
      <NumField
        source="appear_character_36"
        label="appear_character_36"
        sortable={false}
      />
      <NumField
        source="appear_character_37"
        label="appear_character_37"
        sortable={false}
      />
      <NumField
        source="appear_character_38"
        label="appear_character_38"
        sortable={false}
      />
      <NumField
        source="appear_character_39"
        label="appear_character_39"
        sortable={false}
      />
      <NumField
        source="appear_character_40"
        label="appear_character_40"
        sortable={false}
      />
    </Datagrid>
  </CommonMasterList>
);
