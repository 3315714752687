interface Command {
  name: string;
  desc: string;
  params: string;
  api: string;
  method: "POST" | "PUT" | "DELETE";
  result?: string;
  paramConverters?: string;
  disabled?: boolean;
}

export const commands: Command[] = [
  /**
   * ユーザー情報
   */
  {
    name: "--- ユーザー情報 ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "経験値変更",
    desc: "経験値を指定した値に変更します",
    params: '{ "UserId": "1", "Exp": 0 }',
    api: "User/UpdateUserRankExp",
    method: "POST",
  },
  {
    name: "スタミナ変更",
    desc: "スタミナを指定した値に変更します\nサーバ時間が未来に設定されていると本機能は動作しないので、ユーザー単位の時間変更で現行の時刻に戻します",
    params: '{ "UserId": "1", "Stamina": 100 }',
    api: "User/ChangeStamina",
    method: "POST",
  },
  {
    name: "スタミナ回復ステップ変更",
    desc: "Stepは0以上を指定できます。上限を超えた場合、上限に丸めます",
    params: '{ "UserId": "1", "Step": 0, "LastRecoveredAt": "__NOW__" }',
    api: "User/ChangeStoneStaminaRecovery",
    method: "POST",
    paramConverters: '{ "LastRecoveredAt": "ToUTC" }',
  },
  {
    name: "プラットフォーム変更",
    desc: "1: iOS, 2: Android",
    params: '{ "UserId": "1", "Platform": 1 }',
    api: "User/ChangePlatform",
    method: "POST",
  },
  {
    name: "ユーザー作成日時変更",
    desc: "",
    params: '{ "UserId": "1", "CreatedAt": "__NOW__" }',
    api: "User/UpdateUser",
    method: "POST",
    paramConverters: '{ "CreatedAt": "ToUTC" }',
  },
  {
    name: "最終ログイン情報変更",
    desc: "",
    params: '{ "UserId": "1", "LoginDays": 1, "LastLoginAt": "__NOW__" }',
    api: "User/UpdateLogin",
    method: "POST",
    paramConverters: '{ "LastLoginAt": "ToUTC" }',
  },
  {
    name: "誕生日情報削除",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "User/DeleteBirthday",
    method: "POST",
  },
  {
    name: "年齢確認情報更新",
    desc: "",
    params: '{ "UserId": "1", "Year": 2000, "Month": 1, "Day": 1 }',
    api: "User/UpdateBirthMonth",
    method: "POST",
  },
  {
    name: "年齢確認情報削除",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "User/DeleteBirthMonth",
    method: "POST",
  },
  {
    name: "ユーザー削除",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "User/DeleteUser",
    method: "POST",
  },
  {
    name: "ユーザー検索",
    desc: "UserIdの部分一致で検索します",
    params: '{ "UserId": "1" }',
    api: "User/SearchUser",
    method: "POST",
    result: "UserList",
  },
  /**
   * 付与（一般）
   */
  {
    name: "--- 付与（一般） ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "マニー付与",
    desc: "",
    params: '{ "UserId": "1", "Money": 10000 }',
    api: "User/AddMoney",
    method: "POST",
  },
  {
    name: "無償ジュエル付与",
    desc: "",
    params: '{ "UserId": "1", "Count": 10000 }',
    api: "User/AddStone",
    method: "POST",
  },
  {
    name: "有償ジュエル付与",
    desc: "",
    params: '{ "UserId": "1", "Count": 1000 }',
    api: "User/AddBilledStone",
    method: "POST",
  },
  {
    name: "初期ユーザーセット付与",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "User/AddInitialUserSet",
    method: "POST",
  },

  {
    name: "アイテム付与",
    desc: "ItemIdsは複数指定可能。例: [1, 2, 3]",
    params: '{ "UserId": "1", "ItemIds": [1], "Num": 10 }',
    api: "Item/AddItem",
    method: "POST",
  },
  {
    name: "プレゼント付与",
    desc: "ContentType 1: キャラカード, 2: 装備カード, 3: アイテム, 4: お金, 5: ジュエル, 6: 有償ジュエル, 7: ストーリー",
    params:
      '{ "UserId": "1", "ContentType": 1, "ContentId": 101, "ContentNum": 10 }',
    api: "Present/AddPresent",
    method: "POST",
  },
  /**
   * 所持数変更（一般）
   */
  {
    name: "--- 所持数変更（一般） ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "マニー所持数変更",
    desc: "",
    params: '{ "UserId": "1", "Money": 10000 }',
    api: "User/UpdateMoney",
    method: "POST",
  },
  {
    name: "無償ジュエル所持数変更",
    desc: "",
    params: '{ "UserId": "1", "Count": 10000 }',
    api: "User/UpdateStone",
    method: "POST",
  },
  {
    name: "有償ジュエル所持数変更",
    desc: "",
    params: '{ "UserId": "1", "Count": 1000 }',
    api: "User/UpdateBilledStone",
    method: "POST",
  },
  {
    name: "外部決済有償ジュエル所持数変更",
    desc: "",
    params: '{ "UserId": "1", "Count": 1000 }',
    api: "User/UpdateExternalBilledStone",
    method: "POST",
  },
  /**
   * MCカード
   */
  {
    name: "--- MCカード ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "キャラカード付与",
    desc: "CharacterCardIdsは複数指定可能。例: [1, 2, 3]",
    params: '{ "UserId": "1", "CharacterCardIds": [1], "Exp": 0 }',
    api: "Character/AddCharacterCard",
    method: "POST",
  },
  {
    name: "全キャラカード付与",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Character/AddAllCharacterCards",
    method: "POST",
  },
  {
    name: "キャラカードの経験値変更",
    desc: "Expに変更したい経験値を指定します",
    params: '{ "UserId": "1", "CharacterCardId": 1, "Exp": 100 }',
    api: "Character/UpdateCharacterCardExp",
    method: "POST",
  },
  {
    name: "キャラカード削除",
    desc: "デッキにセット済みのキャラカードは削除できません",
    params: '{ "UserId": "1", "CharacterCardId": 1 }',
    api: "Character/DeleteCharacterCard",
    method: "POST",
  },
  {
    name: "キャラカードのレベルをMAXに変更",
    desc: "CharacterCardIdsは複数指定可能。例: [1, 2, 3]\n指定なしの場合は所持キャラカードすべてが対象。例：[]",
    params: '{ "UserId": "1", "CharacterCardIds": [1] }',
    api: "Character/UpdateCharacterCardMaxLevel",
    method: "POST",
  },
  {
    name: "キャラカードのレベルリセット",
    desc: "",
    params: '{ "UserId": "1", "CharacterCardId": 1 }',
    api: "Character/ResetCharacterCardLevel",
    method: "POST",
  },
  {
    name: "キャラカードの限界突破リセット",
    desc: "",
    params: '{ "UserId": "1", "CharacterCardId": 1 }',
    api: "Character/ResetCharacterCardGrade",
    method: "POST",
  },
  {
    name: "キャラカードのパネル強化",
    desc: "PanelIdsはCSV形式で複数指定（例：[1,2,3]）できます",
    params: '{ "UserId": "1", "CharacterCardId": 1, "PanelIds": [1] }',
    api: "Character/EnhanceCharacterCardPanels",
    method: "POST",
  },
  {
    name: "キャラカードのパネルボード強化",
    desc: "",
    params: '{ "UserId": "1", "CharacterCardId": 1, "PanelBoardID": 1 }',
    api: "Character/EnhanceCharacterCardPanelBoard",
    method: "POST",
  },
  {
    name: "キャラカードの全パネルボード強化",
    desc: "CharacterCardIdsは複数指定可能。例: [1, 2, 3]\n指定なしの場合は所持キャラカードすべてが対象。例：[]",
    params: '{ "UserId": "1", "CharacterCardIds": [1] }',
    api: "Character/EnhanceAllCharacterCardPanelBoards",
    method: "POST",
  },
  {
    name: "キャラカードのパネルリセット",
    desc: "",
    params: '{ "UserId": "1", "CharacterCardId": 1 }',
    api: "Character/ResetCharacterCardPanel",
    method: "POST",
  },
  /**
   * メモリーカード
   */
  {
    name: "--- メモリーカード ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "メモリーカード付与",
    desc: "EquipmentCardIdsは複数指定可能。例: [1, 2, 3]",
    params: '{ "UserId": "1", "EquipmentCardIds": [1], "Num": 1 }',
    api: "Equipment/AddEquipmentCard",
    method: "POST",
  },
  {
    name: "全メモリーカード付与",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Equipment/AddAllEquipmentCards",
    method: "POST",
  },
  {
    name: "メモリーカードのレベルをMAXに変更",
    desc: "UserEquipmentCardNumbersは複数指定可能。例: [1, 2, 3]\n指定なしの場合は所持メモリーカードすべてが対象。例：[]\nIsMaxRank: true指定の場合は覚醒段階もMaxにします",
    params:
      '{ "UserId": "1", "UserEquipmentCardNumbers": [1], "IsMaxRank": true }',
    api: "Equipment/UpdateEquipmentCardMaxLevel",
    method: "POST",
  },
  {
    name: "メモリーカードのレベルリセット",
    desc: "",
    params: '{ "UserId": "1", "CardNumber": 1688952337698 }',
    api: "Equipment/ResetEquipmentCardLevel",
    method: "POST",
  },
  /**
   * ガチャ
   */
  {
    name: "--- ガチャ ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "ガチャ履歴削除",
    desc: "",
    params: '{ "UserId": "1", "GachaId": 1 }',
    api: "Gacha/DeleteGacha",
    method: "POST",
  },
  {
    name: "ガチャスタンプ履歴削除",
    desc: "",
    params: '{ "UserId": "1", "GachaStampId": 1 }',
    api: "Gacha/DeleteGachaStamp",
    method: "POST",
  },
  {
    name: "ガチャ購入数変更",
    desc: "LastPurchasedAttはデイリー、ウィークリーの進捗リセット判定に使用されます\nデイリー：LastPurchasedAtが現在日のゲーム開始時間(5:00)より前の場合、進捗がリセットされます\nウィークリー：LastPurchasedAtが現在週(月曜開始)のゲーム開始時間(5:00)より前の場合、進捗がリセットされます",
    params:
      '{ "UserId": "1", "GachaId": 1, "PurchaseCount": 1, "LastPurchasedAt": "__NOW__" }',
    api: "Gacha/UpdateGachaPurchaseCount",
    paramConverters: '{ "LastPurchasedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "ガチャスタンプステップ数変更",
    desc: "",
    params: '{ "UserId": "1", "GachaStampId": 1, "Step": 1}',
    api: "Gacha/UpdateGachaStampStep",
    method: "POST",
  },
  {
    name: "ステップアップガチャ変更",
    desc: "LoopCountはループした回数（0スタート）、Stepは現在のステップ",
    params:
      '{ "UserId": "1", "StepGachaGroupId": 1, "LoopCount": 0, "Step": 1}',
    api: "Gacha/UpdateStepGachaGroup",
    method: "POST",
  },
  {
    name: "限定ガチャ変更",
    desc: "",
    params:
      '{ "UserId": "1", "LimitedGachaId": 1, "IsActive": true, "StartedAt": "__NOW__", "IsDisplayable": true }',
    api: "Gacha/UpdateLimitedGacha",
    paramConverters: '{ "StartedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "限定ガチャ削除",
    desc: "",
    params: '{ "UserId": "1", "LimitedGachaId": 1 }',
    api: "Gacha/DeleteLimitedGacha",
    method: "POST",
  },
  /**
   * ショップ
   */
  {
    name: "--- ショップ ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "ショップの購入制限リセット",
    desc: "",
    params: '{ "UserId": "1", "ShopMerchandiseId": 1 }',
    api: "Shop/ResetShopPurchaseLimit",
    method: "POST",
  },
  {
    name: "ショップの購入回数変更",
    desc: "LastPurchasedAtはデイリー、ウィークリーの購入数リセット判定に使用されます\nデイリー：LastPurchasedAtが現在日のゲーム開始時間(5:00)より前の場合、回数がリセットされます\nウィークリー：LastPurchasedAtが現在週(月曜開始)のゲーム開始時間(5:00)より前の場合、回数がリセットされます",
    params:
      '{ "UserId": "1", "ShopMerchandiseId": 1, "PurchaseCount": 1, "LastPurchasedAt": "__NOW__"}',
    api: "Shop/UpdateShopPurchaseCount",
    paramConverters: '{ "LastPurchasedAt": "ToUTC" }',
    method: "POST",
  },
  /**
   * 決済
   */
  {
    name: "--- 決済 ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "決済履歴の追加",
    desc: "Idは未指定の場合はサーバー側で採番されます",
    params:
      '{ "Id": "", "UserId": "1", "ProductId": "1", "Platform": 1, "CreatedAt": "__NOW__" }',
    api: "Billing/AddUserCommonBilledHistory",
    paramConverters: '{ "CreatedAt": "ToUTC" }',
    method: "POST",
  },
  /**
   * ミッション
   */
  {
    name: "--- ミッション ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "ミッションの進捗変更",
    desc: "LastProgressedAtはデイリー、ウィークリーミッションの進捗リセット判定に使用されます\nデイリー：LastProgressedAtが現在日のゲーム開始時間(5:00)より前の場合、進捗がリセットされます\nウィークリー：LastProgressedAtが現在週(月曜開始)のゲーム開始時間(5:00)より前の場合、進捗がリセットされます",
    params:
      '{ "UserId": "1", "MissionId": 1, "Progress": 1, "LastProgressedAt": "__NOW__" }',
    api: "Mission/UpdateMissionProgress",
    paramConverters: '{ "LastProgressedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "達成数ミッションの進捗変更",
    desc: "LastProgressedAtはデイリー、ウィークリーミッションの進捗リセット判定に使用されます\nデイリー：LastProgressedAtが現在日のゲーム開始時間(5:00)より前の場合、進捗がリセットされます\nウィークリー：LastProgressedAtが現在週(月曜開始)のゲーム開始時間(5:00)より前の場合、進捗がリセットされます",
    params:
      '{ "UserId": "1", "MissionAchievedCountId": 1, "Progress": 1, "LastProgressedAt": "__NOW__" }',
    api: "Mission/UpdateMissionAchievedCountProgress",
    paramConverters: '{ "LastProgressedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "全クリア済み達成数ミッションのリセット",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Mission/ResetAllClearedMissionAchievedCounts",
    method: "POST",
  },
  {
    name: "ミッションクリア",
    desc: "",
    params: '{ "UserId": "1", "MissionId": 1 }',
    api: "Mission/ClearMission",
    method: "POST",
  },
  {
    name: "達成数ミッションクリア",
    desc: "",
    params: '{ "UserId": "1", "MissionAchievedCountId": 1 }',
    api: "Mission/ClearMissionAchievedCount",
    method: "POST",
  },
  {
    name: "クリア済み達成数ミッションのリセット",
    desc: "",
    params: '{ "UserId": "1", "MissionAchievedCountId": 1 }',
    api: "Mission/ResetClearedMissionAchievedCount",
    method: "POST",
  },
  {
    name: "クリア済みミッションのリセット",
    desc: "",
    params: '{ "UserId": "1", "MissionId": 1 }',
    api: "Mission/ResetClearedMission",
    method: "POST",
  },
  {
    name: "全クリア済みミッションのリセット",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Mission/ResetAllClearedMissions",
    method: "POST",
  },
  {
    name: "ミッションのログイン日数+1",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Mission/IncreaseMissionLoginDay",
    method: "POST",
  },
  {
    name: "ミッションのログイン日数リセット",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Mission/ResetMissionLoginDay",
    method: "POST",
  },
  /**
   * バトル
   */
  {
    name: "--- バトル ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "バトル終了",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Battle/FinishBattle",
    method: "POST",
  },
  {
    name: "特定クエストまで開放",
    desc: "",
    params:
      '{ "UserId": "1", "QuestBattleDifficultyId": 1, "CreatedAt": "__NOW__" }',
    api: "Quest/ReleaseQuest",
    paramConverters: '{ "CreatedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "クエストのクリア状態変更",
    desc: "クリア済みにするにはIsClearedにtrue、未クリア状態にするにはfalseを指定してください\nクリア済みに変更した場合はIsPlayedは強制的にtrueになります",
    params:
      '{ "UserId": "1", "QuestBattleDifficultyId": 1, "IsPlayed": true, "IsCleared": true }',
    api: "Quest/UpdateQuestBattleDifficulty",
    method: "POST",
  },
  {
    name: "指定クエスト全クリア",
    desc: "Difficulty 0: すべて, 1: Easy, 2: Normal, 3: Hard, 4: VeryHard",
    params:
      '{ "UserId": "1", "QuestID": 1, "Difficulty": 0, "CreatedAt": "__NOW__" }',
    api: "Quest/ClearAllQuests",
    paramConverters: '{ "CreatedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "指定クエスト全リセット",
    desc: "Difficulty 0: すべて, 1: Easy, 2: Normal, 3: Hard, 4: VeryHard",
    params: '{ "UserId": "1", "QuestID": 1, "Difficulty": 0 }',
    api: "Quest/ResetAllQuests",
    method: "POST",
  },
  {
    name: "クエストミッション達成",
    desc: "",
    params:
      '{ "UserId": "1", "QuestBattleMissionId": 1, "CreatedAt": "__NOW__" }',
    paramConverters: '{ "CreatedAt": "ToUTC" }',
    api: "Quest/ClearQuestBattleMission",
    method: "POST",
  },
  {
    name: "クエストミッション削除",
    desc: "",
    params: '{ "UserId": "1", "QuestBattleMissionId": 1 }',
    api: "Quest/DeleteQuestBattleMission",
    method: "POST",
  },
  /**
   * ストーリー
   */
  {
    name: "--- ストーリー ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "カードストーリー解放",
    desc: "CardStoryEpisodeIDsは複数指定可能。例: [1, 2, 3]\n指定なしの場合はすべて解放。例：[]",
    params:
      '{ "UserId": "1", "CardStoryEpisodeIDs": [1], "CreatedAt": "__NOW__" }',
    api: "Story/ReleaseCardStoryEpisode",
    paramConverters: '{ "CreatedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "クエストストーリー解放",
    desc: "QuestStoryEpisodeIDsは複数指定可能。例: [1, 2, 3]\n指定なしの場合はすべて解放。例：[]",
    params:
      '{ "UserId": "1", "QuestStoryEpisodeIDs": [1], "CreatedAt": "__NOW__" }',
    api: "Story/ReleaseQuestStoryEpisode",
    paramConverters: '{ "CreatedAt": "ToUTC" }',
    method: "POST",
  },
  /**
   * ログインボーナス
   */
  {
    name: "--- ログインボーナス ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "通常ログインボーナス受取回数変更",
    desc: "LastReceiveAtが現在日のゲーム開始時間(5:00)より後の場合、受取処理は実行されません",
    params: '{ "UserId": "1", "ReceivedCount": 1, "LastReceiveAt": "__NOW__"}',
    api: "LoginBonus/UpdateNormalLoginBonusCount",
    paramConverters: '{ "LastReceiveAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "イベントログインボーナス受取回数変更",
    desc: "LastReceiveAtが現在日のゲーム開始時間(5:00)より後の場合、受取処理は実行されません",
    params:
      '{ "UserId": "1", "SeasonId": 1, "ReceivedCount": 1, "LastReceiveAt": "__NOW__"}',
    api: "LoginBonus/UpdateEventLoginBonusCount",
    paramConverters: '{ "LastReceiveAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "イベントログインボーナス全削除",
    desc: "",
    params: '{ "UserId": "1"}',
    api: "LoginBonus/DeleteAllEventLoginBonus",
    method: "POST",
  },
  {
    name: "バースデーログインボーナス削除",
    desc: "",
    params: '{ "UserId": "1", "CharacterId": 1}',
    api: "LoginBonus/DeleteBirthdayLoginBonus",
    method: "POST",
  },
  {
    name: "バースデーログインボーナス全削除",
    desc: "",
    params: '{ "UserId": "1"}',
    api: "LoginBonus/DeleteAllBirthdayLoginBonuses",
    method: "POST",
  },
  /**
   * 称号
   */
  {
    name: "--- 称号 ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "称号ミッションの進捗変更",
    desc: "進捗を未達成状態にした場合、すでに関連する称号を獲得済みの場合は削除されます。削除対象の称号がプロフィールにセットされている場合はエラーになります\n達成状態にした場合、関連する称号も獲得されます。",
    params: '{ "UserId": "1", "HonorMissionId": 1, "Progress": 1 }',
    api: "Honor/UpdateHonorMissionProgress",
    method: "POST",
  },
  {
    name: "称号の付与",
    desc: "称号付与時には関連するミッションも達成済みになります",
    params: '{ "UserId": "1", "HonorId": 1 }',
    api: "Honor/AddHonor",
    method: "POST",
  },
  {
    name: "称号の削除",
    desc: "称号削除時には関連するミッションも削除となります",
    params: '{ "UserId": "1", "HonorId": 1 }',
    api: "Honor/DeleteHonor",
    method: "POST",
  },
  /**
   * バトルパス
   */
  {
    name: "--- バトルパス ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "バトルパス購入情報変更",
    desc: "事前にバトルパス情報が作成されている必要があります",
    params:
      '{ "UserId": "1", "BattlePassSeasonId": 1, "PurchasedAt": "__NOW__" }',
    api: "BattlePass/UpdateBattlePassPurchase",
    paramConverters: '{ "PurchasedAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "バトルパス情報変更",
    desc: "",
    params: '{ "UserId": "1", "BattlePassSeasonId": 1, "Level": 1, "Exp": 0 }',
    api: "BattlePass/UpdateBattlePassSeason",
    method: "POST",
  },
  /**
   * シーズンパス
   */
  {
    name: "--- シーズンパス ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "シーズンパス購入情報変更",
    desc: "OpenAtはシーズンパスを購入してからの開始日です。現在日時との経過日数で受取日数を算出しています\nLastReceiveAtが現在日のゲーム開始時間(5:00)より前の場合、その日の報酬を受取可能です",
    params:
      '{ "UserId": "1", "SeasonPassId": 1, "OpenAt": "__NOW__", "LastReceiveAt": "__NOW__" }',
    api: "SeasonPass/UpdateSeasonPassPurchase",
    paramConverters: '{ "OpenAt": "ToUTC", "LastReceiveAt": "ToUTC" }',
    method: "POST",
  },
  /**
   * Act
   */
  {
    name: "--- ACT ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "ActPass購入情報更新",
    desc: "",
    params:
      '{ "UserId": "1", "ActPassId": 1, "OpenAt": "__NOW__", "LastReceiveAt": "__NOW__" }',
    api: "Act/AddActPassPurchase",
    paramConverters: '{ "OpenAt": "ToUTC", "LastReceiveAt": "ToUTC" }',
    method: "POST",
  },
  {
    name: "Act開始時間更新",
    desc: "",
    params:
      '{ "UserId": "1", "SlotNumber": 1, "StartedAt": "__NOW__", "ActLevel": 1 }',
    api: "Act/AddActStartTime",
    paramConverters: '{ "StartedAt": "ToUTC" }',
    method: "POST",
  },
  /**
   * PVP
   */
  {
    name: "--- PVP ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "PVPランキングスコア変更",
    desc: "事前に指定シーズンのPVPにエントリーしている必要があります",
    params: '{ "UserId": "1", "SeasonId": 1, "Score": 0 }',
    api: "PVP/UpdatePVPRankingScore",
    method: "POST",
  },
  {
    name: "PVP集計初期化",
    desc: "SeasonIdに初期化したいPvpSeasonのIdを指定します",
    params: '{ "SeasonId": 1}',
    api: "PVP/InitPVPAggregate",
    method: "POST",
  },
  {
    name: "PVP集計実行",
    desc: "SeasonIdに集計したいPvpSeasonのIdを指定します",
    params: '{ "SeasonId": 1}',
    api: "PVP/RunPVPAggregate",
    method: "POST",
  },
  /**
   * チュートリアル
   */
  {
    name: "--- チュートリアル ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "チュートリアル進捗変更",
    desc: "IsGachaExecutedはチュートリアルガチャを実行したかどうか\nIsDoneはチュートリアルが完了したかどうか",
    params:
      '{ "UserId": "1", "Progress": 0, "IsGachaExecuted": false, "IsDone": false }',
    api: "Tutorial/UpdateTutorialProgress",
    method: "POST",
  },
  {
    name: "チュートリアルスキップ",
    desc: "",
    params: '{ "UserId": "1" }',
    api: "Tutorial/SkipTutorial",
    method: "POST",
  },
  /**
   * 時間変更
   */
  {
    name: "--- 時間変更 ---",
    desc: "",
    params: "",
    api: "",
    method: "POST",
    disabled: true,
  },
  {
    name: "時間の変更（全体）",
    desc: "ユーザー単位ではなくすべての時間を変更します。使用には注意してください\nEnabled: trueで基準となる時間を変更できます。falseで時間を元に戻せます\n指定する時間のタイムゾーンはJSTです",
    params: '{ "Enabled": true, "RequestTime": "__NOW__"}',
    api: "RequestTime/ChangeGlobalRequestTime",
    method: "POST",
    paramConverters: '{ "RequestTime": "ToUTC" }',
  },
  {
    name: "時間の変更（ユーザー単位）",
    desc: "Enabled: trueで基準となる時間を変更できます。falseで時間を元に戻せます\n指定する時間のタイムゾーンはJSTです",
    params: '{ "UserId": "1", "Enabled": true, "RequestTime": "__NOW__"}',
    api: "RequestTime/ChangeRequestTime",
    method: "POST",
    paramConverters: '{ "RequestTime": "ToUTC" }',
  },
  {
    name: "時間変更の確認（全体）",
    desc: "時間変更が有効になっている場合はセットされた時間が表示されます\n無効の場合は何も表示されません\nBaseTimeが基準となる時間\nRequestTimeが変更した時間\nGameTimeがゲーム内の時間",
    params: "{}",
    api: "RequestTime/InspectGlobalRequestTime",
    method: "POST",
    paramConverters: '{ "RequestTime": "ToUTC" }',
    result: "ResultCard",
  },
  {
    name: "時間変更の確認（ユーザー単位）",
    desc: "時間変更が有効になっている場合はセットされた時間が表示されます\n無効の場合は何も表示されません\nBaseTimeが基準となる時間\nRequestTimeが変更した時間\nGameTimeがゲーム内の時間",
    params: '{ "UserId": "1"}',
    api: "RequestTime/InspectRequestTime",
    method: "POST",
    paramConverters: '{ "RequestTime": "ToUTC" }',
    result: "ResultCard",
  },
];
