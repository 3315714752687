import { Datagrid, TextField, DateField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const MissionAchievedCountList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <NumField
        source="mission_group_id"
        label="mission_group_id"
        sortable={false}
      />
      <TextField source="text" label="text" sortable={false} />
      <NumField source="category" label="category" sortable={false} />
      <NumField source="value" label="value" sortable={false} />
      <NumField source="exp" label="exp" sortable={false} />
      <NumField
        source="mission_reward_group_id"
        label="mission_reward_group_id"
        sortable={false}
      />
      <DateField source="open_at" label="open_at" sortable={false} />
      <DateField source="close_at" label="close_at" sortable={false} />
    </Datagrid>
  </CommonMasterList>
);
