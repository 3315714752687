import { Datagrid, TextField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const EquipmentCardList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <TextField source="name" label="name" sortable={false} />
      <TextField source="card_name" label="card_name" sortable={false} />
      <NumField source="character" label="character" sortable={false} />
      <NumField source="division" label="division" sortable={false} />
      <NumField source="rarity" label="rarity" sortable={false} />
      <NumField source="hp_min" label="hp_min" sortable={false} />
      <NumField source="atk_min" label="atk_min" sortable={false} />
      <NumField
        source="equipment_card_parameter_group_id"
        label="equipment_card_parameter_group_id"
        sortable={false}
      />
      <NumField source="hp_gain" label="hp_gain" sortable={false} />
      <NumField source="atk_gain" label="atk_gain" sortable={false} />
      <NumField source="def_min" label="def_min" sortable={false} />
      <NumField source="def_gain" label="def_gain" sortable={false} />
      <NumField
        source="passive_skill_id_1"
        label="passive_skill_id_1"
        sortable={false}
      />
      <NumField
        source="equipment_card_skill_condition_group_id_1"
        label="equipment_card_skill_condition_group_id_1"
        sortable={false}
      />
      <NumField
        source="passive_skill_id_2"
        label="passive_skill_id_2"
        sortable={false}
      />
      <NumField
        source="equipment_card_skill_condition_group_id_2"
        label="equipment_card_skill_condition_group_id_2"
        sortable={false}
      />
      <NumField
        source="passive_skill_id_3"
        label="passive_skill_id_3"
        sortable={false}
      />
      <NumField
        source="equipment_card_skill_condition_group_id_3"
        label="equipment_card_skill_condition_group_id_3"
        sortable={false}
      />
      <TextField source="flavor_text" label="flavor_text" sortable={false} />
      <TextField
        source="is_single_equip_only"
        label="is_single_equip_only"
        sortable={false}
      />
      <NumField
        source="awaken_item_id"
        label="awaken_item_id"
        sortable={false}
      />
      <NumField
        source="awaken_item_num"
        label="awaken_item_num"
        sortable={false}
      />
      <NumField
        source="card_illust_asset_id"
        label="card_illust_asset_id"
        sortable={false}
      />
      <TextField
        source="home_text_bubble_position"
        label="home_text_bubble_position"
        sortable={false}
      />
    </Datagrid>
  </CommonMasterList>
);
