import { Datagrid, TextField, DateField, BooleanField } from "react-admin";
import { CommonMasterList, NumField } from "../Common";

export const HonorList = (props: any) => (
  <CommonMasterList {...props}>
    <Datagrid bulkActionButtons={false}>
      <NumField source="id" label="id" sortable={false} />
      <TextField source="name" label="name" sortable={false} />
      <TextField source="long_name" label="long_name" sortable={false} />
      <TextField
        source="requirement_text"
        label="requirement_text"
        sortable={false}
      />
      <TextField source="image_path" label="image_path" sortable={false} />
      <BooleanField
        source="is_image_hidden"
        label="is_image_hidden"
        sortable={false}
      />
      <BooleanField
        source="is_name_hidden"
        label="is_name_hidden"
        sortable={false}
      />
      <BooleanField
        source="is_requirement_hidden"
        label="is_requirement_hidden"
        sortable={false}
      />
      <DateField source="open_at" label="open_at" sortable={false} />
      <DateField source="close_at" label="close_at" sortable={false} />
    </Datagrid>
  </CommonMasterList>
);
