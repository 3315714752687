export enum Group {
  Announcement = "announcement",
  Ban = "ban",
  Kpi = "kpi",
  Log = "log",
  Maintenance = "maintenance",
  Manage = "manage",
  Master = "master",
  Other = "other",
  Present = "present",
  User = "user",
}
