import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, NumField, YMDHMSDateField } from "../Common";

const userQuestBattleMissionFilters = [
  <NumberInput source="QuestBattleMissionId" label="QuestBattleMissionId" />,
];

export const UserQuestBattleMissionList = (props: any) => (
  <CommonList {...props} addFilters={userQuestBattleMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="QuestBattleMissionId" label="QuestBattleMissionId" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
