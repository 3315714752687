import { Pagination } from "react-admin";

interface CommonPaginationProps {
  rowsPerPageOptions?: number[];
  [key: string]: any;
}

export const CommonPagination = ({
  rowsPerPageOptions = [10, 50, 100],
  ...props
}: CommonPaginationProps) => (
  <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
);
